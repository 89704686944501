// src/pages/Leads/components/SkeletonCard.js
import React from 'react';
import { Card, CardContent, CardActions, Skeleton } from '@mui/material';

export default function SkeletonCard() {
  return (
    <Card sx={{ margin: 2, backgroundColor: 'background.paper', boxShadow: 3 }}>
      <CardContent>
        <Skeleton variant="text" height={30} />
        <Skeleton variant="text" width="60%" />
        <Skeleton variant="rectangular" height={20} sx={{ mt: 1 }} />
        <Skeleton variant="text" height={20} sx={{ mt: 2 }} />
      </CardContent>
      <CardActions>
        <Skeleton variant="rectangular" width={80} height={30} />
      </CardActions>
    </Card>
  );
}
