// FindLeadsButton.jsx

import React from 'react';
import { Button } from '@mui/material';

const CustomGreenButton = ({ text }) => {
  return (
    <Button
      variant="text"
      disableElevation
      onClick={() => window.location.href = '/home'}
      sx={{
        alignItems: 'center',
        appearance: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        borderBottomColor: 'rgb(0, 167, 111)',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        borderBottomStyle: 'none',
        borderBottomWidth: '0px',
        borderImageOutset: 0,
        borderImageRepeat: 'stretch',
        borderImageSlice: '100%',
        borderImageSource: 'none',
        borderImageWidth: 1,
        borderLeftColor: 'rgb(0, 167, 111)',
        borderLeftStyle: 'none',
        borderLeftWidth: '0px',
        borderRightColor: 'rgb(0, 167, 111)',
        borderRightStyle: 'none',
        borderRightWidth: '0px',
        borderTopColor: 'rgb(0, 167, 111)',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderTopStyle: 'none',
        borderTopWidth: '0px',
        boxShadow: 'none',
        boxSizing: 'border-box',
        color: 'rgb(0, 167, 111)',
        cursor: 'pointer',
        display: 'flex',
        fontFamily: '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        fontSize: '14px',
        fontSizeAdjust: 'none',
        fontStretch: '100%',
        fontStyle: 'normal',
        fontWeight: 700,
        height: '36px',
        justifyContent: 'center',
        letterSpacing: 'normal',
        lineHeight: '24px',
        marginBottom: '0px',
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        minWidth: '64px',
        outlineColor: 'rgb(0, 167, 111)',
        outlineStyle: 'none',
        outlineWidth: '0px',
        paddingBlockEnd: '6px',
        paddingBlockStart: '6px',
        paddingBottom: '6px',
        paddingInlineEnd: '8px',
        paddingInlineStart: '8px',
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '6px',
        position: 'relative',
        textAlign: 'center',
        textDecorationColor: 'rgb(0, 167, 111)',
        textDecorationLine: 'none',
        textDecorationStyle: 'solid',
        textDecorationThickness: 'auto',
        textIndent: '0px',
        textRendering: 'auto',
        textShadow: 'none',
        textSizeAdjust: '100%',
        textTransform: 'none',
        transitionBehavior: 'normal, normal, normal, normal',
        transitionDelay: '0s, 0s, 0s, 0s',
        transitionDuration: '0.25s, 0.25s, 0.25s, 0.25s',
        transitionProperty: 'background-color, box-shadow, border-color, color',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1), cubic-bezier(0.4, 0, 0.2, 1)',
        userSelect: 'none',
        verticalAlign: 'middle',
        // Additional styling for hover effect (optional)
        '&:hover': {
          backgroundColor: 'rgba(0, 167, 111, 0.1)',
        },
      }}
      endIcon={
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="iconify iconify--eva mnl__icon__root MuiBox-root css-cnvj7y" width="1em" height="1em" viewBox="0 0 24 24">
          <path fill="currentColor" d="M10 19a1 1 0 0 1-.64-.23a1 1 0 0 1-.13-1.41L13.71 12L9.39 6.63a1 1 0 0 1 .15-1.41a1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19"></path>
        </svg>
      }
    >
      {text}
    </Button>
  );
};

export default CustomGreenButton;
