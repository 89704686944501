import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCF6xlgRLkmdk7h_I0okCczWMaKivYZWEY",
  authDomain: "nextgen-react-app-439601.firebaseapp.com",
  projectId: "nextgen-react-app-439601",
  storageBucket: "nextgen-react-app-439601.appspot.com",
  messagingSenderId: "1009422792820",
  appId: "1:1009422792820:web:aac916beead190f4a6b921",
  measurementId: "G-K98PK89XWS"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics };
