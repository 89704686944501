// src/pages/Leads/components/FilterSection.js

import React, { useState } from 'react';
import {
  TextField,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default function FilterSection({
  open,
  onClose,
  handleSearch,
}) {
  const [localFilters, setLocalFilters] = useState({
    searchText: '',
    selectedCategory: [],
    dateRange: [null, null],
  });

  // Set fullScreen to false to prevent the dialog from covering the whole screen on mobile
  const fullScreen = false;

  const classifications = {
    0: 'All',
    1: 'Handyman',
    2: 'Plumber',
    3: 'Electrician',
    4: 'Landscaper',
    5: 'HVAC Technician',
    6: 'Roofer',
    7: 'Painter',
    8: 'Pest Control',
    9: 'General Contractor',
    10: 'Gutter Cleaning',
    11: 'Housekeeper',
    12: 'Carpenter'
  };

  const handleInputChange = (event) => {
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      searchText: event.target.value,
    }));
  };

  const handleCategoryToggle = (categoryId) => {
    setLocalFilters((prevFilters) => {
      let newSelectedCategory = [...prevFilters.selectedCategory];
      if (categoryId === 0) {
        // 'All' category
        if (newSelectedCategory.includes(0)) {
          // 'All' is being unchecked
          newSelectedCategory = [];
        } else {
          // 'All' is being checked
          newSelectedCategory = [0];
        }
      } else {
        // Other categories
        if (newSelectedCategory.includes(categoryId)) {
          // Category is being unchecked
          newSelectedCategory = newSelectedCategory.filter((id) => id !== categoryId);
        } else {
          newSelectedCategory.push(categoryId);
        }
        // If 'All' is selected, remove it
        newSelectedCategory = newSelectedCategory.filter((id) => id !== 0);
      }
      return { ...prevFilters, selectedCategory: newSelectedCategory };
    });
  };

  const handleDateRangeChange = (type, newValue) => {
    setLocalFilters((prevFilters) => {
      const [start, end] = prevFilters.dateRange;
      if (type === 'start') {
        return {
          ...prevFilters,
          dateRange: [newValue, end && newValue > end ? null : end],
        };
      } else {
        return {
          ...prevFilters,
          dateRange: [start && newValue < start ? null : start, newValue],
        };
      }
    });
  };

  const handleResetFilters = () => {
    setLocalFilters({
      searchText: '',
      selectedCategory: [],
      dateRange: [null, null],
    });
  };

  const isResetDisabled =
    localFilters.selectedCategory.length === 0 &&
    localFilters.searchText.trim() === '' &&
    localFilters.dateRange.every((date) => date === null);

  const handleSearchClick = () => {
    handleSearch(localFilters);
    onClose();
  };

  const isSearchDisabled = localFilters.selectedCategory.length === 0;

  // Sort categories alphabetically by their names
  const sortedCategories = Object.entries(classifications).sort(([, a], [, b]) =>
    a.localeCompare(b)
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
      disableScrollLock={true} // Added this line
      PaperProps={{
        sx: {
          borderRadius: 2,
          overflow: 'visible',
        },
      }}
      BackdropProps={{
        sx: {
          backdropFilter: 'blur(5px)',
          backgroundColor: 'rgba(0,0,0,0.3)',
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 'bold', textAlign: 'center' }}>
        Search Leads
      </DialogTitle>
      <DialogContent>
        <Box sx={{ padding: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
          {/* Categories Section */}
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: 13, mb: 1 }}>
            Categories (Required)
          </Typography>
          <Grid container spacing={0.5} sx={{ rowGap: 0.5 }}>
            {sortedCategories.map(([id, category]) => (
              <Grid item xs={6} sm={4} key={id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={localFilters.selectedCategory.includes(Number(id))}
                      onChange={() => handleCategoryToggle(Number(id))}
                      disabled={
                        localFilters.selectedCategory.includes(0) && Number(id) !== 0
                      }
                      size="small"
                      sx={{ padding: 0.3 }}
                    />
                  }
                  label={
                    <Typography variant="body2" sx={{ fontSize: 11 }}>
                      {category}
                    </Typography>
                  }
                  sx={{ margin: 0, height: 32 }}
                />
              </Grid>
            ))}
          </Grid>

          <Divider sx={{ marginY: 1 }} />

          {/* Date Pickers */}
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: 13, mb: 1 }}>
            Date (Optional)
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <DatePicker
                label="From"
                value={localFilters.dateRange[0]}
                onChange={(newValue) => handleDateRangeChange('start', newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    sx={{
                      '& .MuiInputBase-root': {
                        padding: '2px 8px',
                        fontSize: 12,
                        minHeight: '32px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: 1,
                      },
                    }}
                  />
                )}
              />
              <DatePicker
                label="To"
                value={localFilters.dateRange[1]}
                onChange={(newValue) => handleDateRangeChange('end', newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    fullWidth
                    sx={{
                      '& .MuiInputBase-root': {
                        padding: '2px 8px',
                        fontSize: 12,
                        minHeight: '32px',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: 1,
                      },
                    }}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>

          <Divider sx={{ marginY: 1 }} />

          {/* Search Text Field */}
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: 13, mb: 1 }}>
            Text (Optional)
          </Typography>
          <TextField
            label=""
            variant="outlined"
            size="small"
            value={localFilters.searchText}
            onChange={handleInputChange}
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                padding: '2px 8px',
                fontSize: 12,
                minHeight: '32px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: 1,
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={handleResetFilters}
          disabled={isResetDisabled}
          sx={{
            color: isResetDisabled ? 'text.secondary' : 'black',
            textTransform: 'none',
            fontSize: 12,
            minWidth: 60,
          }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          onClick={handleSearchClick}
          disabled={isSearchDisabled}
          sx={{
            textTransform: 'none',
            fontSize: 12,
            minWidth: 60,
          }}
        >
          Search
        </Button>
      </DialogActions>
    </Dialog>
  );
}
