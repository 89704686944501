// src/components/NavBar.js

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Divider,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
// import StarIcon from '@mui/icons-material/Star'; // Premium icon (commented out)
// import ContactMailIcon from '@mui/icons-material/ContactMail'; // Contact icon (commented out)
import InfoIcon from '@mui/icons-material/Info';

// Define navigation items with icons and paths
const navItems = [
  {
    label: 'Home',
    path: '/home',
    icon: <HomeIcon />,
  },
  // Uncomment the following objects when Premium and Contact pages are ready
  /*
  {
    label: 'Premium',
    path: '/premium',
    icon: <StarIcon />,
  },
  {
    label: 'Contact',
    path: '/contact',
    icon: <ContactMailIcon />,
  },
  */
  {
    label: 'About',
    path: '/about',
    icon: <InfoIcon />,
  },
];

// Create a custom theme to match the main page's background and typography
const customNavTheme = createTheme({
  palette: {
    mode: 'light', // Use 'light' mode for smooth gray backgrounds
    background: {
      default: '#f9f9f9', // Match the main page's background
      paper: '#f9f9f9',   // Same as default for consistency
    },
    primary: {
      main: 'rgb(28, 37, 46)', // Dark gray for text and icons
    },
    secondary: {
      main: '#f5f5f5', // Light gray for buttons or secondary elements
    },
    text: {
      primary: 'rgb(28, 37, 46)', // Primary text color
      secondary: 'rgba(28, 37, 46, 0.6)', // Secondary text color
    },
  },
  typography: {
    fontFamily:
      '"Public Sans Thin", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    button: {
      fontWeight: 500,
      fontSize: '14px', // As per the provided CSS
      textTransform: 'none', // Removes the uppercase styling
    },
    h6: {
      fontSize: '1.1rem',
      fontWeight: 500,
      color: 'rgb(28, 37, 46)',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          border: 'none',
          boxShadow: 'none',
          color: 'rgb(28, 37, 46)',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          lineHeight: '22px',
          padding: '0 12px',
          transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
          '&:active': {
            transform: 'scale(0.98)',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: 'rgb(28, 37, 46)',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '22px',
          paddingTop: '8px',
          paddingBottom: '8px',
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
        },
      },
    },
  },
});

export default function NavBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();

  // Adjust breakpoint to include larger screens if needed
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Changed from 'sm' to 'md'

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <Box
      sx={{
        width: 250,
        bgcolor: 'background.paper',
        height: '100vh',
        paddingTop: 2,
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {/* Logo at the top of the Drawer */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 2,
        }}
      >
        <RouterLink to="/" style={{ textDecoration: 'none' }}>
          <img
            src="/slf_logo.webp"
            alt="Logo"
            style={{ height: '40px' }}
          />
        </RouterLink>
      </Box>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              component={RouterLink}
              to={item.path}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <ThemeProvider theme={customNavTheme}>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Lighter shadow for gray theme
          paddingX: isMobile ? 1 : 3,
          minHeight: '56px', // Reduced height for NavBar
        }}
      >
        <Toolbar
          sx={{
            minHeight: '56px !important', // Override default Toolbar minHeight
            paddingY: 0, // Remove vertical padding
          }}
        >
          {isMobile ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {/* Hamburger Menu Icon on the Left */}
              <IconButton
                edge="start"
                color="primary" // Changed from 'inherit' to 'primary' for better visibility
                aria-label="menu"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>

              {/* Drawer for Navigation Items */}
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                {drawer}
              </Drawer>

              {/* Logo on the Right */}
              <Box
                component={RouterLink}
                to="/" // Make the logo navigate to the home page
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'text.primary',
                }}
              >
                {/* Use the logo from public folder */}
                <img
                  src="/slf_logo.webp"
                  alt="Logo"
                  style={{ height: '40px' }}
                />
              </Box>
            </Box>
          ) : (
            <>
              {/* Logo on the Left for Larger Screens */}
              <Box
                component={RouterLink}
                to="/" // Make the logo navigate to the home page
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'text.primary',
                  marginLeft: '139.2px', // Adjust as per your layout
                }}
              >
                {/* Use the logo from public folder */}
                <img
                  src="/slf_logo.webp"
                  alt="Logo"
                  style={{ height: '40px' }}
                />
              </Box>

              <Box sx={{ flexGrow: 1 }} />

              {/* Navigation Buttons on the Right for Larger Screens */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '99.2px', // Adjust as per your layout
                }}
              >
                {navItems.map((item) => (
                  <Button
                    key={item.label}
                    color="inherit"
                    component={RouterLink}
                    to={item.path}
                    sx={{
                      marginLeft: 2,
                      height: '40px', // Reduced height for buttons
                      paddingY: '8px', // Adjust vertical padding
                      paddingX: '16px', // Adjust horizontal padding
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {/* Icons only in the Drawer, not in the desktop menu */}
                    {item.label}
                  </Button>
                ))}
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
