// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Leads from './pages/Leads/Leads';
import Home from './pages/Home/Home';
//import Premium from './pages/Premium/Premium';
//import Contact from './pages/Contact/Contact';
import About from './pages/About/About';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import TermsOfService from './pages/Legal/TermsOfService';
import Faq from './pages/Legal/Faq';
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebaseConfig";
import './App.css';

function App() {
  const [isFooterVisible, setIsFooterVisible] = useState(true);

  useEffect(() => {
    // Log page view when component mounts
    logEvent(analytics, 'page_view');
  }, []);

  return (
    <Router>
      {/* Place ScrollToTop here */}
      <ScrollToTop />

      <div className="App">
        {/* Navbar */}
        <div className="NavBar">
          <NavBar />
        </div>

        {/* Main Content */}
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            {/* <Route path="/premium" element={<Premium />} /> */}
            {/* <Route path="/contact" element={<Contact />} /> */}
            <Route path="/about" element={<About />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/faq" element={<Faq />} />
            <Route
              path="/leads"
              element={<Leads setIsFooterVisible={setIsFooterVisible} />}
            />
          </Routes>
        </div>

        {/* Footer */}
        {isFooterVisible && (
          <div className="Footer">
            <Footer />
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
