// src/pages/Leads/components/ExpandableText.js
import React, { useState } from 'react';
import { Typography, Button, Box } from '@mui/material';

export default function ExpandableText({ text, maxLength }) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  if (text.length <= maxLength) {
    return <Typography variant="body1">{text}</Typography>;
  }

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          position: 'relative',
          display: 'inline-block',
          maxWidth: expanded ? '100%' : '100%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          ...(expanded && { whiteSpace: 'normal' }),
        }}
      >
        {text}
        {!expanded && (
          <Box
            component="span"
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              width: '20%',
              height: '100%',
              background: 'linear-gradient(to right, transparent, white)',
              pointerEvents: 'none',
            }}
          />
        )}
      </Typography>
      <Button
        size="small"
        onClick={toggleExpanded}
        sx={{ textTransform: 'none', padding: 0, color: 'primary.main', fontWeight: 'bold' }}
      >
        {expanded ? 'see less' : 'see more'}
      </Button>
    </>
  );
}
