// src/pages/TermsOfService/TermsOfService.js

import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Theme for consistent styling
const theme = createTheme({
  typography: {
    fontFamily: '"Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    h3: {
      fontWeight: 800,
      fontSize: '2.5rem',
      color: 'rgba(128, 128, 128, 0.6)',
      textAlign: 'center',
      marginBottom: '1rem',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.5rem',
      color: 'rgb(28, 37, 46)',
      marginBottom: '0.5rem',
    },
    body1: {
      fontSize: '1rem',
      color: 'rgba(28, 37, 46, 0.7)',
      lineHeight: '1.75rem',
      marginBottom: '1.25rem',
    },
  },
  palette: {
    background: {
      default: '#f9f9f9',
    },
    text: {
      primary: '#333333',
    },
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#f5f5f5',
    },
  },
});

const TermsOfService = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ pt: 10, pb: 25 }}>
        <Typography variant="h3">
          Terms of Service for SmarterLeadFinder
        </Typography>
        <Typography variant="body2" align="center" sx={{ color: 'rgba(28, 37, 46, 0.5)', marginBottom: '2rem' }}>
          Last updated: November 04, 2024
        </Typography>

        <Box sx={{ maxWidth: '800px', marginX: 'auto', paddingX: { xs: 2, sm: 3, md: 4 } }}>
          <Typography variant="h4">1. Acceptance of Terms</Typography>
          <Typography variant="body1">
            By accessing and using the SmarterLeadFinder website, you agree to comply with and be bound by these Terms of Service. If you do not agree with these terms, please do not use our Service.
          </Typography>

          <Typography variant="h4">2. Description of Service</Typography>
          <Typography variant="body1">
            SmarterLeadFinder offers access to business leads across various industries. Users may browse, search, and obtain leads based on their requirements.
          </Typography>

          <Typography variant="h4">3. Use of Leads</Typography>
          <Typography variant="body1">
            Leads obtained through SmarterLeadFinder are for your personal or business use only and should not be resold, redistributed, or shared in a way that violates these Terms. Any misuse of leads may result in suspension or termination of access.
          </Typography>

          <Typography variant="h4">4. Payment Terms</Typography>
          <Typography variant="body1">
            Certain features of SmarterLeadFinder may require payment. By using a paid service, you agree to pay the specified fees. Payments are non-refundable unless otherwise stated.
          </Typography>

          <Typography variant="h4">5. Limitation of Liability</Typography>
          <Typography variant="body1">
            SmarterLeadFinder is not responsible for the accuracy or usefulness of the leads provided. You agree that any reliance on leads is at your own risk. SmarterLeadFinder shall not be liable for any damages or losses resulting from your use of our Service.
          </Typography>

          <Typography variant="h4">6. Changes to Terms</Typography>
          <Typography variant="body1">
            We may update these Terms of Service from time to time. Changes will be posted on this page, and it is your responsibility to review these terms periodically.
          </Typography>

          <Typography variant="h4">7. Contact Us</Typography>
          <Typography variant="body1">
            If you have any questions about these Terms of Service, please contact us at smartleadfinderhelp@gmail.com.
          </Typography>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default TermsOfService;
