import React, { useState, useEffect } from 'react';
import { Fab, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { throttle } from 'lodash';

export default function BackToTop({ bottom = 32, right = 32 }) {
  const [visible, setVisible] = useState(false);

  const handleScroll = throttle(() => {
    const currentScroll = window.pageYOffset;
    setVisible(currentScroll > 300); // Show button after 300px scroll
  }, 200); // Throttle the scroll event for performance

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, [handleScroll]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Zoom in={visible}>
      <Fab
        size="small"
        aria-label="scroll back to top"
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          bottom: `${bottom}px`,
          right: `${right}px`,
          backgroundColor: '#0A1B2A', // Matching dark blue-black background color
          color: '#FFFFFF', // White icon color for contrast
          zIndex: 1000,
          transition: 'background-color 0.3s ease',
          '&:hover': {
            backgroundColor: '#16243B', // Slightly lighter blue-gray on hover for subtle effect
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Subtle shadow on hover
          },
        }}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </Zoom>
  );
}
