// src/pages/Leads/components/LeadCard.js

import React from 'react';
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Box,
  SvgIcon,
} from '@mui/material';
import moment from 'moment-timezone';
import ExpandableText from './ExpandableText';

// Classification mapping
const classifications = {
  1: 'Handyman',
  2: 'Plumber',
  3: 'Electrician',
  4: 'Landscaper',
  5: 'HVAC Technician',
  6: 'Roofer',
  7: 'Painter',
  8: 'Pest Control',
  9: 'General Contractor',
  10: 'Gutter Cleaning',
  11: 'Housekeeper',
  12: 'Carpenter'
};

// Category color mapping
const categoryColors = {
  Handyman: '#795548',
  Plumber: '#2196f3',
  Electrician: '#ff9800',
  Landscaper: '#4caf50',
  'HVAC Technician': '#ffeb3b',
  Roofer: '#6d4c41',
  Painter: '#e91e63',
  'Pest Control': '#ffeb3b',
  'General Contractor': '#3f51b5',
  'Gutter Cleaning': '#607d8b',
  Housekeeper: '#9c27b0',
  Carpenter: '#8d6e63'
};

// Custom Message Icon as SVG
function CustomMessageIcon(props) {
  return (
    <SvgIcon {...props} sx={{ fontSize: '1rem', marginRight: '2px' }}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 10c0-4.422 3.578-8 8-8h4c4.422 0 8 3.578 8 8 0 4.422-3.578 8-8 8h-1.52a16.643 16.643 0 0 1-1.378 1.461c-.992.934-2.456 2.07-4.086 2.426a1.403 1.403 0 0 1-1.455-.563 1.545 1.545 0 0 1-.197-1.395c.28-.818.395-1.725.434-2.451.014-.254.018-.48.018-.665A7.98 7.98 0 0 1 2 10Zm5.797 6.149v.004l.001.007.002.024a7.031 7.031 0 0 1 .014.383 13.693 13.693 0 0 1-.284 3.029c.776-.376 1.541-.97 2.202-1.592a14.973 14.973 0 0 0 1.448-1.578l.017-.022.003-.004a1 1 0 0 1 .8-.4h2c3.318 0 6-2.682 6-6s-2.682-6-6-6h-4c-3.318 0-6 2.682-6 6a5.983 5.983 0 0 0 3.257 5.33 1 1 0 0 1 .54.819Z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}

// Custom Category Icon (if image exists, it uses the image; otherwise fallback to placeholder)
function CategoryIcon({ label, color }) {
  let iconSrc;

  switch (label) {
    case 'Handyman':
      iconSrc = `${process.env.PUBLIC_URL}/category_icons/handyman.webp`;
      break;
    case 'Plumber':
      iconSrc = `${process.env.PUBLIC_URL}/category_icons/plumber.webp`;
      break;
    case 'Electrician':
      iconSrc = `${process.env.PUBLIC_URL}/category_icons/electrician.webp`;
      break;
    case 'Landscaper':
      iconSrc = `${process.env.PUBLIC_URL}/category_icons/landscaper.webp`;
      break;
    case 'Roofer':
      iconSrc = `${process.env.PUBLIC_URL}/category_icons/roofer.webp`;
      break;
    case 'Painter':
      iconSrc = `${process.env.PUBLIC_URL}/category_icons/painter.webp`;
      break;
    case 'Pest Control':
        iconSrc = `${process.env.PUBLIC_URL}/category_icons/pestcontrol.webp`;
        break;
    case 'General Contractor':
      iconSrc = `${process.env.PUBLIC_URL}/category_icons/generalcontractor.webp`;
      break;
    case 'Housekeeper':
      iconSrc = `${process.env.PUBLIC_URL}/category_icons/housekeeper.webp`;
      break;
    case 'Carpenter':
      iconSrc = `${process.env.PUBLIC_URL}/category_icons/carpenter.webp`;
      break;
    default:
      iconSrc = null;
      break;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        backgroundColor: '#fff',
        border: `2px solid ${color}`,
        padding: '1px',
        textAlign: 'center',
        marginRight: '8px',
      }}
    >
      {iconSrc ? (
        <img src={iconSrc} alt={label} style={{ width: '80%', height: '80%' }} />
      ) : (
        'i' // Placeholder icon
      )}
    </Box>
  );
}

export default function LeadCard({ lead }) {
  const formatTimestamp = (timestamp) => {
    // Parse the given UTC timestamp
    const postTime = moment.utc(timestamp); // Keep in UTC
    
    // Get the current time in UTC
    const now = moment.utc();
    const diffInMinutes = now.diff(postTime, 'minutes');
    const diffInHours = now.diff(postTime, 'hours');
    const diffInDays = now.diff(postTime, 'days');

    if (diffInMinutes < 1) {
        return 'Just now';
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes} min ago`;
    } else if (diffInHours < 24) {
        return `${diffInHours} hr${diffInHours > 1 ? 's' : ''} ago`;
    } else if (diffInDays < 30) {
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    } else {
        return postTime.format('DD MMM YY');
    }
  };

  const labelId = lead.label !== null && lead.label !== undefined ? lead.label : 0;
  const categoryName = classifications[labelId];
  const categoryColor = categoryColors[categoryName] || '#9e9e9e';

  return (
    <Card
      sx={{
        margin: 2,
        backgroundColor: '#FAFAFA',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        border: '1px solid #f0f0f0', // Light gray border for structure
        borderRadius: '12px', // Slightly rounded corners for a modern look
      }}
    >
      <CardContent>
        <Typography variant="h6">{lead.author}</Typography>
        <Typography variant="body2" color="textSecondary">
          {lead.neighborhood} • {formatTimestamp(lead.timestamp)}
        </Typography>

        {/* Centered category section */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', // Center the category
            marginTop: 1,
            color: '#000',
            fontSize: '0.75rem',
            fontWeight: 500,
          }}
        >
          <CategoryIcon label={categoryName} color={categoryColor} />
          {categoryName}
        </Box>

        <Box sx={{ marginTop: 2 }}>
          <ExpandableText text={lead.text} maxLength={100} />
        </Box>
      </CardContent>

      <CardActions
        sx={{
          justifyContent: 'flex-start', // Align the button to the left
          paddingLeft: 2, // Slight padding for the button to avoid being too close to the edge
          paddingBottom: 2, // Add bottom padding for some space between button and card bottom
        }}
      >
        {lead.href && (
          <Button
            size="small"
            variant="contained"
            href={lead.href}
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<CustomMessageIcon />}
            sx={{
              backgroundColor: '#f5f5f5',
              color: '#000',
              fontSize: '0.625rem',
              border: '1px solid #f5f5f5',
              borderRadius: '24px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#e0e0e0',
                border: '1px solid #e0e0e0',
              },
              margin: '0 4px', // Add a bit more margin around the button
            }}
          >
            Contact
          </Button>
        )}
      </CardActions>
    </Card>
  );
}
