// src/pages/Home/Home.js

import React, { useState } from 'react';
import { Box, Typography, Button, Grid, Fade } from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FilterSection from '../components/FilterSection';
import { useNavigate } from 'react-router-dom';
import '@fontsource/barlow/800.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/public-sans/300.css';

const theme = createTheme({
  typography: {
    fontFamily: '"Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    h3: {
      fontWeight: 800,
      fontSize: '72px',
      lineHeight: '90px',
      color: 'rgba(128, 128, 128, 0.4)',
      textAlign: 'center',
      WebkitFontSmoothing: 'antialiased',
      '@media (max-width:600px)': {
        fontSize: '48px',
        lineHeight: '60px',
      },
    },
    h4: {
      fontWeight: 800,
      fontSize: '72px',
      lineHeight: '90px',
      color: 'rgb(28, 37, 46)',
      textAlign: 'center',
      WebkitFontSmoothing: 'antialiased',
      '@media (max-width:600px)': {
        fontSize: '48px',
        lineHeight: '60px',
      },
    },
    body1: {
      fontSize: '1.125rem',
      color: 'rgba(28, 37, 46, 0.6)',
      textAlign: 'center',
      maxWidth: '600px',
      margin: '0 auto',
      '@media (max-width:600px)': {
        fontSize: '0.8rem',  // Smaller font size for mobile screens
      },
    },
    button: {
      fontWeight: 500,
      textTransform: 'none',
    },
  },
  palette: {
    background: {
      default: '#f9f9f9',
    },
    text: {
      primary: '#333333',
    },
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#f5f5f5',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'transform 0.2s, background-color 0.2s, box-shadow 0.2s',
        },
      },
    },
  },
});

const Home = () => {
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const isFilterOpen = Boolean(filterAnchorEl);

  const handleSearch = (filters) => {
    // Build query parameters from filters
    const params = new URLSearchParams();

    if (filters.searchText) {
      params.append('searchText', filters.searchText);
    }

    if (filters.selectedCategory.length > 0) {
      params.append('selectedCategory', filters.selectedCategory.join(','));
    }

    if (filters.dateRange[0]) {
      params.append('startDate', filters.dateRange[0].toISOString().split('T')[0]);
    }
    if (filters.dateRange[1]) {
      params.append('endDate', filters.dateRange[1].toISOString().split('T')[0]);
    }

    // Navigate to /leads with query parameters
    navigate(`/leads?${params.toString()}`);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: '10vh',
          backgroundColor: 'background.default',
          backgroundImage: 'url("/assets/pattern.svg")',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          spacing={4}
          sx={{
            width: '100%',
            maxWidth: '100%',
          }}
        >
          {/* Top Section */}
          <Grid item>
            <Fade in={true} timeout={1000}>
              <Typography variant="h3">
                Powered by AI
              </Typography>
            </Fade>
            <Fade in={true} timeout={1000}>
              <Typography variant="h4" sx={{ display: 'inline', mt: 1 }}>
                access quality{' '}
                <span style={{
                  background: 'linear-gradient(90deg, #32CD32, #20B2AA, #66CDAA, #FFD700, #FFA500, #32CD32)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  display: 'inline-block',
                  animation: 'subtleGreenGoldFlow 8s ease-in-out infinite',
                  backgroundSize: '300% 100%'
                }}>
                  leads
                </span>
              </Typography>
            </Fade>
          </Grid>

          {/* Middle Section */}
          <Grid item>
            <Fade in={true} timeout={1200}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: '"Public Sans Thin", sans-serif',
                  color: 'rgb(28, 37, 46)',
                  lineHeight: 2,
                }}
              >
                Discover a tool crafted to make finding leads easier.<br />
                Simplify and streamline your search process effortlessly.
              </Typography>
            </Fade>
          </Grid>

          {/* Bottom Section */}
          <Grid item>
            <Fade in={true} timeout={1500}>
              <Button
                variant="contained"
                startIcon={<SearchRoundedIcon />}
                sx={{
                  width: { xs: '200px', sm: '250px', md: '280px' },
                  height: '45px',
                  borderRadius: '25px',
                  bgcolor: 'white',
                  color: 'primary.main',
                  boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    bgcolor: 'secondary.main',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                    transform: 'scale(1.05)',
                  },
                  '&:active': {
                    transform: 'scale(0.98)',
                  },
                }}
                onClick={handleFilterClick}
                aria-label="Search Leads"
              >
                Search Leads
              </Button>
            </Fade>
          </Grid>

          {/* Filter Section */}
          <FilterSection
            anchorEl={filterAnchorEl}
            open={isFilterOpen}
            onClose={handleFilterClose}
            handleSearch={(filters) => {
              handleSearch(filters);
              handleFilterClose();
            }}
          />
        </Grid>
      </Box>

      {/* Add Global CSS for keyframes */}
      <style jsx="true">{`
        @keyframes subtleGreenGoldFlow {
          0% { background-position: 0% 0%; }
          50% { background-position: 100% 0%; }
          100% { background-position: 0% 0%; }
        }
      `}</style>
    </ThemeProvider>
  );
};

export default Home;
