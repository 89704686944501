// About.jsx

import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Rating,
  Container,
  Fade,
  Card,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CustomGreenButton from '../../components/CustomGreenButton'; // Import the reusable button component
import Carousel from 'react-multi-carousel'; // Import react-multi-carousel
import 'react-multi-carousel/lib/styles.css'; // Import react-multi-carousel styles

// Custom Theme
const theme = createTheme({
  typography: {
    fontFamily: '"Roboto", sans-serif',
    h2: { fontSize: '32px', fontWeight: 700, color: '#333' },
    h3: { fontSize: '28px', fontWeight: 700, color: '#333', textAlign: 'center' },
    h4: { fontSize: '24px', fontWeight: 600, color: '#555', textAlign: 'center' },
    overline: { fontSize: '14px', color: '#888', textTransform: 'uppercase', letterSpacing: '1px' },
    body1: { fontSize: '18px', color: '#555', textAlign: 'center' },
    body2: { fontSize: '16px', color: '#777' },
  },
  palette: {
    primary: { main: '#00A76F' }, // Updated primary color to match button color
    background: { default: '#f9f9f9' },
  },
});

// Sample Testimonials Data - Updated with fake names
const testimonials = [
  {
    name: 'Sophia Martinez',
    date: 'January 1, 2024',
    rating: 5,
    feedback: 'Excellent Work! Thanks a lot!',
    avatar: 'https://randomuser.me/api/portraits/women/44.jpg',
  },
  {
    name: 'Liam Johnson',
    date: 'February 15, 2024',
    rating: 5,
    feedback: 'Outstanding service and support.',
    avatar: 'https://randomuser.me/api/portraits/men/46.jpg',
  },
  {
    name: 'Emma Williams',
    date: 'March 10, 2024',
    rating: 5,
    feedback: 'Great experience overall.',
    avatar: 'https://randomuser.me/api/portraits/women/47.jpg',
  },
  {
    name: 'Noah Brown',
    date: 'April 5, 2024',
    rating: 5,
    feedback: 'Highly recommend to others.',
    avatar: 'https://randomuser.me/api/portraits/men/6.jpg',
  },
  {
    name: 'Ava Davis',
    date: 'May 20, 2024',
    rating: 5,
    feedback: 'Exceptional quality and support.',
    avatar: 'https://randomuser.me/api/portraits/women/49.jpg',
  },
  {
    name: 'Oliver Miller',
    date: 'June 18, 2024',
    rating: 5,
    feedback: 'Very satisfied with the results.',
    avatar: 'https://randomuser.me/api/portraits/men/22.jpg',
  },
  {
    name: 'Isabella Wilson',
    date: 'July 30, 2024',
    rating: 5,
    feedback: 'Good service, will return again.',
    avatar: 'https://randomuser.me/api/portraits/women/51.jpg',
  },
  {
    name: 'Elijah Moore',
    date: 'August 12, 2024',
    rating: 5,
    feedback: 'Exceeded my expectations.',
    avatar: 'https://randomuser.me/api/portraits/men/52.jpg',
  },
];

const About = () => {
  // Responsive settings for react-multi-carousel
  const responsive = {
    superLargeDesktop: {
      // screens greater than 1600px
      breakpoint: { max: 4000, min: 0 }, // Covers all screen sizes
      items: 1, // Only 1 item at a time
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', pt: 10, pb: 25 }}>
        <Container maxWidth="lg">
          {/* Who We Are Section */}
          <Fade in timeout={1000}>
            <Typography variant="h3">Who We Are</Typography>
          </Fade>
          <Fade in timeout={1200}>
            <Typography variant="body1" sx={{ mt: 2, maxWidth: '800px', mx: 'auto' }}>
              Our platform empowers professionals with streamlined access to quality leads.
              By leveraging AI, we simplify the search process, helping you connect with
              meaningful opportunities while focusing on delivering exceptional service.
            </Typography>
          </Fade>

          {/* Mission and Vision Section */}
          <Grid container spacing={5} sx={{ mt: 5 }} alignItems="center">
            <Grid item xs={12} md={6}>
              <Fade in timeout={1400}>
                <Box>
                  <Typography variant="h4">Our Mission</Typography>
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    We are committed to fostering innovation and integrity in everything we do.
                    Our mission is to streamline the connection between professionals and
                    opportunities, empowering individuals and businesses to achieve more.
                  </Typography>
                </Box>
              </Fade>
            </Grid>
            <Grid item xs={12} md={6}>
              <Fade in timeout={1600}>
                <Box>
                  <img
                    src="https://assets.minimals.cc/public/assets/images/about/vision.webp"
                    alt="Our Mission"
                    style={{ width: '100%', borderRadius: '8px' }}
                  />
                </Box>
              </Fade>
            </Grid>
          </Grid>
        </Container>

        {/* Testimonials Section - Full Width */}
        <Box
          sx={{
            mt: 10,
            bgcolor: '#1a1a1a',
            color: 'blue',
            py: 8, // Increased padding to make section taller
            width: '100%',
            minHeight: '500px', // Maintained height
          }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={5}>
              {/* Left Side Text */}
              <Grid item xs={12} md={6}>
                <Fade in timeout={1800}>
                  <Typography
                    variant="overline"
                    sx={{
                      fontSize: '12px',
                      fontWeight: 700,
                      color: 'rgba(255, 255, 255, 0.48)',
                      textTransform: 'uppercase',
                      lineHeight: '18px',
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      textAlign: 'left',
                      display: 'block',
                      mb: 2, // Added margin bottom for spacing
                    }}
                  >
                    Testimonials
                  </Typography>
                </Fade>
                <Fade in timeout={2000}>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '48px',
                        fontWeight: 800,
                        color: 'rgb(255, 255, 255)',
                        lineHeight: '64px',
                        textAlign: 'left',
                        fontFamily:
                          'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                        mt: 1, // You can adjust this for spacing
                        mb: 1, // Reduced margin bottom to decrease space
                      }}
                    >
                      Who love
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: '48px',
                        fontWeight: 800,
                        color: 'rgb(255, 255, 255)',
                        lineHeight: '64px',
                        textAlign: 'left',
                        fontFamily:
                          'Barlow, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      }}
                    >
                      my work
                    </Typography>
                  </Box>
                </Fade>
                <Fade in timeout={2200}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: '16px',
                      fontWeight: 400,
                      color: 'rgb(255, 255, 255)',
                      lineHeight: '24px',
                      textAlign: 'left',
                      fontFamily:
                        '"Public Sans Variable", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                      mt: 2,
                    }}
                  >
                    Our goal is to connect you with valuable leads<br />
                    that make a real impact on your business.<br />
                    We’re committed to improving our process<br />
                    so you get the best possible results<br />
                    and unmatched service.
                  </Typography>
                </Fade>
                {/* Added Reusable Button below the text */}
                <Fade in timeout={2400}>
                  <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-start' }}>
                    <CustomGreenButton text="Find Leads" />
                  </Box>
                </Fade>
              </Grid>

              {/* Right Side Carousel */}
              <Grid item xs={12} md={6}>
                <Box sx={{ position: 'relative' }}>
                  <Carousel
                    responsive={responsive}
                    ssr // means to render carousel on server-side.
                    infinite
                    autoPlay
                    autoPlaySpeed={3000}
                    keyBoardControl
                    showDots={false} // Dots have been removed
                    arrows
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={[]}
                    itemClass="carousel-item-padding-40-px"
                  >
                    {testimonials.map((testimonial, index) => (
                      <Box key={index} sx={{ px: 2 }}>
                        <Card
                          sx={{
                            bgcolor: 'rgba(255, 255, 255, 0.08)',
                            p: 4,
                            pt: 4, // Adjusted top padding
                            pb: 4, // Adjusted bottom padding if necessary
                            maxWidth: '100%',
                            height: '350px', // Maintained height
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            textAlign: 'center',
                            borderRadius: '16px', // Rounded borders
                            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                            position: 'relative', // To position SVG
                          }}
                          elevation={3}
                        >
                          {/* SVG Symbol in Top-Left Corner */}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 16, // 16px from the top
                              left: 16, // 16px from the left
                              color: '#B0B0B0', // Off-grayish color
                              fontSize: '48px', // Adjust size as needed
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              role="img"
                              className="iconify iconify--mingcute mnl__icon__root MuiBox-root css-1xcianc"
                              width="1em"
                              height="1em"
                              viewBox="0 0 24 24"
                            >
                              <g fill="none">
                                <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                                <path
                                  fill="currentColor"
                                  d="M8.4 6.2a1 1 0 0 1 1.2 1.6c-1.564 1.173-2.46 2.314-2.973 3.31A3.5 3.5 0 1 1 4 14.558a7.6 7.6 0 0 1 .508-3.614C5.105 9.438 6.272 7.796 8.4 6.2m9 0a1 1 0 0 1 1.2 1.6c-1.564 1.173-2.46 2.314-2.973 3.31A3.5 3.5 0 1 1 13 14.558a7.6 7.6 0 0 1 .508-3.614c.598-1.506 1.764-3.148 3.892-4.744"
                                ></path>
                              </g>
                            </svg>
                          </Box>

                          {/* Enlarged Avatar */}
                          <Avatar
                            src={testimonial.avatar}
                            sx={{ width: 80, height: 80, mb: 2, mt: 4 }} // Increased size to 80px
                          />
                          {/* Enlarged Name */}
                          <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: 'white', fontSize: '20px' }}>
                            {testimonial.name}
                          </Typography>
                          {/* Enlarged Date */}
                          <Typography variant="body1" sx={{ color: 'white', mb: 1, fontSize: '16px' }}>
                            {testimonial.date}
                          </Typography>
                          {/* Enlarged Feedback */}
                          <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic', color: 'white', fontSize: '18px' }}>
                            "{testimonial.feedback}"
                          </Typography>
                          {/* Rating (unchanged) */}
                          <Rating
                            value={testimonial.rating}
                            readOnly
                            size="small"
                            sx={{ color: '#FFD700' }} // Gold color for stars
                          />
                        </Card>
                      </Box>
                    ))}
                  </Carousel>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default About;
