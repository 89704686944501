// src/pages/FAQ/FAQ.js

import React from 'react';
import { Box, Typography, Container, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// Reuse theme from Home page for consistency
const theme = createTheme({
  typography: {
    fontFamily: '"Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    h4: {
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '2.5rem',
      color: 'rgb(28, 37, 46)',
      textAlign: 'left',
      marginBottom: '1rem',
    },
    body1: {
      fontSize: '1rem',
      color: 'rgba(28, 37, 46, 0.7)',
      lineHeight: '1.75rem',
    },
  },
  palette: {
    background: {
      default: '#f9f9f9',
    },
    text: {
      primary: '#333333',
    },
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#f5f5f5',
    },
  },
});

const FAQ = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ paddingY: '2rem', pt: 10, pb: 25 }}>
        <Typography variant="h3" align="center" sx={{ fontWeight: 800, fontSize: '2.5rem', marginBottom: '2rem', color: 'rgba(128, 128, 128, 0.6)' }}>
          Frequently Asked Questions (FAQ)
        </Typography>

        <Box>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq1-content" id="faq1-header">
              <Typography variant="h4">1. What is SmarterLeadFinder?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                SmarterLeadFinder is a platform that provides business leads across various industries for users looking to grow their client base.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq2-content" id="faq2-header">
              <Typography variant="h4">2. Are the leads updated regularly?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                Yes, we strive to keep our leads database up-to-date to provide you with the latest opportunities.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq3-content" id="faq3-header">
              <Typography variant="h4">3. Are there any costs associated with SmarterLeadFinder?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                We offer both free and paid services. Our paid services provide access to additional features and exclusive leads.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq4-content" id="faq4-header">
              <Typography variant="h4">4. How can I search for specific types of leads?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                You can use our search and filter options to find leads tailored to your industry and requirements.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq5-content" id="faq5-header">
              <Typography variant="h4">5. Can I share or resell the leads I obtain?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                No, the leads are for your personal or business use only and cannot be resold or shared without permission.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq6-content" id="faq6-header">
              <Typography variant="h4">6. What if I have an issue with my payment?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                If you encounter any issues, please contact our support team at smartleadfinderhelp@gmail.com, and we’ll be happy to assist you.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="faq7-content" id="faq7-header">
              <Typography variant="h4">7. How do I cancel a paid service?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                To cancel a paid service, contact our support team, and we will guide you through the cancellation process.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default FAQ;
