// src/pages/Leads/Leads.js

import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation and useNavigate
import './Leads.css'; // Import the CSS for transitions

// Components
import FilterSection from '../components/FilterSection';
import LeadCard from './components/LeadCard';
import SkeletonCard from './components/SkeletonCard';
import BackToTop from './components/BackToTop'; // Import BackToTop

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

// Main Theme
const customTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#FFFFFF',
      paper: '#F5F5F5',
    },
    primary: {
      main: '#1F1F1F',
    },
  },
});

export default function Leads({ setIsFooterVisible }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [leads, setLeads] = useState([]);
  const [hasMore, setHasMore] = useState(false); // Initialize to false
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // State for pagination and filters
  const [page, setPage] = useState(0);

  // Parse filters from URL query parameters
  const query = new URLSearchParams(location.search);
  const initialFilters = {
    searchText: query.get('searchText') || '',
    selectedCategory: query.get('selectedCategory')
      ? query.get('selectedCategory').split(',').map(Number)
      : [],
    dateRange: [
      query.get('startDate') ? new Date(query.get('startDate')) : null,
      query.get('endDate') ? new Date(query.get('endDate')) : null,
    ],
  };

  const [filters, setFilters] = useState(initialFilters);

  // New state to track if a search has been performed
  const [hasSearched, setHasSearched] = useState(
    filters.searchText.trim() !== '' ||
    filters.selectedCategory.length > 0 ||
    filters.dateRange.some((date) => date !== null)
  );

  // State for FilterSection Dialog
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleFilterOpen = () => {
    setIsFilterOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterOpen(false);
  };

  // Function to fetch data from the backend
  const fetchData = async () => {
    try {
      setIsLoading(true);

      const startDate = filters.dateRange[0]
        ? filters.dateRange[0].toISOString().split('T')[0]
        : '';
      const endDate = filters.dateRange[1]
        ? filters.dateRange[1].toISOString().split('T')[0]
        : '';

      // Determine pageSize based on whether it's the initial load or a scroll fetch
      const currentPageSize = page === 0 ? 20 : 15;

      const url = `https://us-central1-nextgen-react-app-439601.cloudfunctions.net/getLeads?page=${page}&pageSize=${currentPageSize}&searchText=${encodeURIComponent(
        filters.searchText
      )}&selectedCategory=${encodeURIComponent(
        filters.selectedCategory.join(',')
      )}&startDate=${startDate}&endDate=${endDate}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data.length < currentPageSize) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      setLeads((prevLeads) => (page === 0 ? data : [...prevLeads, ...data]));
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Too many requests. Please try again in 5 minutes.');
      setIsLoading(false);
    }
  };

  // Load more data when user scrolls down
  const fetchMoreData = () => {
    if (isLoading || !hasMore) return;
    setPage((prevPage) => prevPage + 1);
  };

  // Fetch data when filters or page changes
  useEffect(() => {
    if (!hasSearched) return;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, page]);

  // Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams();

    if (filters.searchText) {
      params.append('searchText', filters.searchText);
    }

    if (filters.selectedCategory.length > 0) {
      params.append('selectedCategory', filters.selectedCategory.join(','));
    }

    if (filters.dateRange[0]) {
      params.append('startDate', filters.dateRange[0].toISOString().split('T')[0]);
    }
    if (filters.dateRange[1]) {
      params.append('endDate', filters.dateRange[1].toISOString().split('T')[0]);
    }

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [filters, navigate, location.pathname]);

  // Handle search when user clicks the Search button
  const handleSearch = (newFilters) => {
    setFilters(newFilters);
    setHasSearched(true); // Indicate that a search has been performed
    setPage(0);
    setHasMore(true); // Enable infinite scroll
    setLeads([]);
    setIsLoading(true);
    handleFilterClose(); // Close the filter popup
  };

  // Hide the footer when Leads component mounts
  useEffect(() => {
    setIsFooterVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show footer when all data is loaded
  useEffect(() => {
    if (!isLoading && !hasMore) {
      setIsFooterVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, hasMore]);

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh', paddingBottom: 5 }}>
        {/* Search Button at Top Right */}
        {hasSearched && (
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: 2 }}>
            <Button
              variant="contained"
              startIcon={<SearchRoundedIcon />}
              onClick={handleFilterOpen} // Updated handler
              sx={{
                textTransform: 'none',
                backgroundColor: '#1F1F1F',
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: '#333333',
                },
              }}
            >
              Search
            </Button>
          </Box>
        )}

        {/* Filter Section Popup */}
        <FilterSection
          open={isFilterOpen}
          onClose={handleFilterClose}
          handleSearch={handleSearch}
        />

        {/* Error Message */}
        {error && (
          <Typography variant="h6" color="error" gutterBottom align="center">
            {error}
          </Typography>
        )}

        {/* Loading Skeletons During Initial Load */}
        {isLoading && leads.length === 0 ? (
          <Box sx={{ padding: 2 }}>
            <Grid container spacing={2}>
              {[...Array(9)].map((_, idx) => (
                <Grid item xs={12} sm={6} md={4} key={`skeleton-${idx}`}>
                  <SkeletonCard />
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          // Conditionally Render Infinite Scroll Only After a Search
          hasSearched && (
            <InfiniteScroll
              dataLength={leads.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <Box sx={{ padding: 2 }}>
                  <Grid container spacing={2}>
                    {[...Array(3)].map((_, idx) => (
                      <Grid item xs={12} sm={6} md={4} key={`skeleton-${idx}`}>
                        <SkeletonCard />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              }
              style={{ overflow: 'hidden' }}
            >
              {/* Display No Results Message */}
              {leads.length === 0 && !isLoading && hasSearched && (
                <Typography variant="h6" align="center">
                  No results found for your search criteria.
                </Typography>
              )}

              {/* TransitionGroup for Fade-In Effects */}
              <Grid container spacing={2} sx={{ padding: 2 }}>
                <TransitionGroup component={null}>
                  {leads.map((lead, index) => (
                    <CSSTransition key={lead.id || index} timeout={500} classNames="fade">
                      <Grid item xs={12} sm={6} md={4}>
                        <LeadCard lead={lead} />
                      </Grid>
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </Grid>
            </InfiniteScroll>
          )
        )}

        {/* Back to Top Button */}
        <BackToTop />
      </Box>
    </ThemeProvider>
  );
}
